import { Link, useRouteLoaderData } from "@remix-run/react"
import dayjs from "dayjs"
import { cn } from "~/utils/shadecn"

export default function PromotionBanner() {
  const { user, sub } = useRouteLoaderData("root")

  return (
    <div
      className={cn(
        "relative bg-primary/90 text-center text-zinc-50 max-w-4xl mx-auto py-1 px-2 md:py-3 md:px-4 mb-4 rounded-lg",
        sub && sub.Package.RecurUnit === 12 && "hidden"
      )}>
      {user ?
        <>
          {sub ?
            <Link to="/billing" className="text-balance hover:underline text-xs sm:text-sm">
              <b>Save up to $100</b>
              <br />
              Upgrade to an annual subscription today.
            </Link>
          : dayjs(user.CreatedAt) > dayjs().subtract(1, "month") ?
            <Link to="/subscribe" className="text-balance hover:underline text-xs sm:text-sm">
              <b>Gift For New Members</b>
              <br />A welcome bundle of credits. Watch any 3 videos for $7.
            </Link>
          : <Link to="/subscribe" className="text-balance hover:underline text-xs sm:text-sm">
              <b>Want 1 for $1?</b>
              <br />
              Subscribe today.
            </Link>
          }
        </>
      : <Link to="/register" className="text-balance hover:underline text-xs sm:text-sm">
          <b>Someone Just Shared Something 🔥</b>
          <br /> See new real world sex videos every day
        </Link>
      }
    </div>
  )
}
